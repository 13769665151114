const StartingPointStyle = {

area:{
    marginLeft : '10px',
    margin: 'none', 
    width: '120px'
  },
  number:{
    marginLeft: '10px',
    width: '80px',
    margin: 'none'
  },
  zip:{
    marginLeft: '10px',
    width: '100px',
    margin: 'none'
  },
  street:{
  },
  button2: {
    position: 'relative',
    top: '13px',
    marginLeft: '10px'
    },
  icon: {
    
    position: 'relative',
    top: '6px',
    color : '#006400'
      },
  container: {
    width: '800px', /* Can be in percentage also. */
    height: 'auto',
    margin: '0 auto',
    padding: '10px',
    position: 'relative'
}
}

export default StartingPointStyle;