import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';


const myTheme = createMuiTheme({
  overrides: {
    MuiInputLabel: { 
      root: { 
        color:'black',
        fontSize: 13, 
      },
    },
  },

  palette: {
      primary: {
          main: '#3b3c36'
        },
      secondary: {
          main: '#DD4B3E'
      },
    status: {
        danger: red,
    }
}


})


export default myTheme;