import { Container, Draggable } from "react-smooth-dnd";
import arrayMove from "array-move";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography"
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import styles from './SortMarkersStyle'





const useStyles = makeStyles(styles);





export const SortMarkers = ({markersList,setMarkersList,markers,LockList,setMarkers,withStarting}) => {
  //  const [checked,setChecked] = useState(new Array(markers.length).fill(false))      merge destinations feature
  //  const [isActive,setisActive] = useState(false);

    const classes = useStyles();
    const onDrop = ({ removedIndex, addedIndex }) => {
       console.log({ removedIndex, addedIndex });
        if (withStarting) 
          setMarkersList(items => arrayMove(items, removedIndex+1, addedIndex+1)); 
        else 
          setMarkersList(items => arrayMove(items, removedIndex, addedIndex));
      };

    const handleRemoveItem = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const name = e.currentTarget.getAttribute("name")  
      markers.forEach(function (mark) {
        if (mark.id === parseInt(name)) {
          mark.station = false;
        }
      })
      setMarkersList(markersList.filter(item => item.id !== parseInt(name)))
    }

  //   const handleCheck = (e) => {
  //     console.log(e.target.checked)                                                merge destinations feature
  //     console.log(e.target.value)
  //     checked[e.target.value-1] = e.target.checked;
  //  //   console.log(checked)
  //     if ( checked.filter(Boolean).length >1 ) setisActive(true)
  //     else setisActive(false)
    // }

  //   const handleSubmit = (e) => {
  //     var first = true;
  //     var parent_id = -1;
  //     for (var i = 0; i < checked.length; i++) {
  //       if (checked[i] && !first){
  //         setMarkersList(markersList.filter(item => item.id !== i+1))
  //         setMarkers(markers.filter(item=> item.id !== i+1))
  //         markers[parent_id-1].shop_name += "\n" + markers[i].shop_name
  //         checked[i] = false;

  //       }
  //       if (first && checked[i]) {
  //         parent_id = i+1
  //         first = false
  //         checked[i] = false;
  //       }

  //   }
    

  // }



  if (!LockList) {   
    
    return (
      <List className = {classes.root} width = {1} >
        <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop} >
          {markersList.map(({ id, name,shop_address,area,ΧΚΒ,ΣΑΚ},index) => (
            (id !== 0) &&
            <Draggable key={id} className="drag-handle">
              <ListItem width = {1} className = {classes.listitems} style = {{cursor: 'move'}}>
                {/* <Checkbox value = {id} onChange = {e => handleCheck(e,"change")}/> */}
                <IconButton 
                  name = {id}
                  aria-label="delete" 
                  className = {classes.button}
                  onClick={(e) => handleRemoveItem(e)}
                >
                  <DeleteIcon/>
                </IconButton>
                <ListItemText primary={name + " : " + shop_address + ", " + area} width= {1} className="drag-handle"/>
                <ListItemSecondaryAction className="drag-handle">
                  <ListItemIcon className="drag-handle">
                     <DragHandleIcon/>
                  </ListItemIcon>
                </ListItemSecondaryAction>
                <Typography>{withStarting ? index : index+1}</Typography>
                {/* <Typography> ΧΚΒ: {ΧΚΒ} ΣΑΚ: {ΣΑΚ}  {index+1}</Typography> */}
              </ListItem>
            </Draggable>
      
            
          ))}
        </Container>
        {/* {  isActive && <Button variant="contained" color="primary" fullWidth onClick = {e => handleSubmit(e)}>  merge feature
          Κανε κλικ εδω για να συγχωνευσεις τις επιλεγμενες τοποθεσίες σε μία
        
        </Button> } */}
        
      </List>

      
    );
  }
  else return (
        <List className = {classes.root} width = {1} >
          <Container  onDrop={onDrop}  >
            {markersList.map(({ id, name,shop_address,area,ΧΚΒ,ΣΑΚ},index) => (
              <ListItem width = {1} className = {classes.listitems}  >
                <ListItemText primary={name + " : " + shop_address + ", " + area} width= {1} />
                <Typography>{withStarting ? index : index+1}</Typography>
                {/* <Typography> ΧΚΒ:{ΧΚΒ} | ΣΑΚ:{ΣΑΚ}             {index+1}</Typography> */}
              </ListItem>
              
            ))}
          </Container>
        </List>
    );
};

