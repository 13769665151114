import React, { useState } from 'react';
import XLSX from 'xlsx';
import { make_cols } from './MakeColumns';
import { SheetJSFT } from './types';
import Geocode from "react-geocode"
import {Button,makeStyles} from "@material-ui/core"

 


const useStyles = makeStyles((theme) => ({
button: {
  background: '#f8f8ff',
  color: '#DD4B3E',
  marginRight: '10px'

}

  
}));


Geocode.setApiKey("AIzaSyDcxXTLU55s9HxZaHcPWofqBUujAhYuSrk"); //AIzaSyB4Cxe3kulopoAubczivwFc3MvRi76B374
Geocode.setLanguage("gr");
Geocode.setRegion("gr");
Geocode.setLocationType("ROOFTOP");

 async function findGeo(address) {
  return Geocode.fromAddress(address)
  .then(
    (response) => {
    return response
    },
    (error) => {
      console.error(error);
    }
  );
}




const ExcelReader = ({data,setData,startingAddr,setMarkersList,withStarting}) => {

  const classes = useStyles();

  const [file,setFile] = useState('');
  // eslint-disable-next-line
  const [cols,setCols] = useState([]);
  
  const[error,setError] = useState(null)

 
const  handleChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) setFile(files[0])
    setError('')
  };
 
    const handleFile = () => {
    if(file){
    //console.log(file)
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    
 
      reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const dataz = XLSX.utils.sheet_to_json(ws);
      
      /* Update state */
      setCols(make_cols(ws['!ref']))
      let muteData = dataz
      if (withStarting)
        muteData.push(startingAddr)
      muteData.forEach((obj,index1) => {
        if (obj.shop_address){
          //console.log(obj.shop_address)
          obj.full_address= obj.shop_address + ", " + obj.zip + ", " + obj.area
        if  (obj["Α/Α"] !== undefined){
          //console.log("hey",obj["Α/Α"])
          obj.id = obj["Α/Α"]
          obj.station = false
        if(obj.id === 0) {
          obj.station = true;
          setMarkersList(oldarray => [...oldarray,obj])
        }
        
        findGeo(obj.full_address)
        .then((res) => {
          if(res){
          obj.position = res.results[0].geometry.location
          muteData.forEach((lola,index2) => {
            if (((obj.position.lat === lola.position?.lat) && (obj.position.lng === lola.position?.lng) ) && (obj.id !== lola.id)){
              obj.position.lat = lola.position.lat + 0.0001
              //console.log(index1,obj.position,index2,lola.position,"succ")
            }
            
            //console.log(index1,obj.position,index2,lola.position)

          })
         // console.log("Hey",res.results[0].geometry.location)
          if(obj.name) setData(oldarray => [...oldarray,obj])
        }
        else {setFile(null)
        setError('Σφάλμα, δοκιμάστε με άλλο αρχείο. Αν το πρόβλημα επιμένει επικοινωνήστε μαζί μας. ')
        }
        }
        )
        }
        else {
          //console.log("I got here nice")
          setFile(null)
          setError('Δεν βρέθηκε πεδίο A/A στο αρχείο Excel! ')
        }
        }
        else {
          //console.log("Here too")
          setFile(null)
          setError('Δεν βρέθηκε πεδίο shop_address στο αρχείο Excel! ')
          }
        }
        )

    
      

    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    };
  }
  else(setError('Δεν έχετε εισάγει αρχείο! '))
  }

 
    return (
      <div>
        {/* <label htmlFor="file">Φορτώστε το αρχείο excel</label> */}
        {error ? <bh > <font color="#ff0000">{error}</font></bh> : null }
        <bh />
        <input type="file" className="form-control" style={{ display: 'none' }} id="file" accept={SheetJSFT} onChange={e => handleChange(e)}/>
        <label htmlFor="file">
          <Button component="span" 
          className={classes.button}
          variant='contained'
          color='primary'
          >
            Επιλογη αρχειου
          </Button>
        </label> 
        <bh />
        <Button 
          
          variant='contained'
          onClick={handleFile}
          color='secondary' >
          Φορτωση Σημειων
        </Button>
        
          </div>
      
    )
}
 
export default ExcelReader;


