import React,{useState} from 'react'
import {Grid,TextField,Button,makeStyles,Typography} from '@material-ui/core' 
import styles from './StartingPointStyle'
import DoneIcon from '@material-ui/icons/Done';
   

   
const useStyles = makeStyles(styles);   
   
const StartingPoint = ({setStartingAddr,setWithStarting}) => {
    
    const classes = useStyles();

    const [set,setSet] = useState(false)
    const [street,setStreet] = useState('')
    const [number,setNumber] = useState('')
    const [zip,setZip] = useState('')
    const [area,setArea] = useState('')

    const onStreetChange = e => {
        setStreet(e.target.value);
    }

    const onNumberChange = e => {
        setNumber(e.target.value);
    }

    const onZipChange = e => {
        setZip(e.target.value);
    }

    const onAreaChange = e => {
        setArea(e.target.value);
    }

    const handleSubmit = () => {
        let full_addr;
        full_addr = {name:'ΣΗΜΕΙΟ ΕΚΚΙΝΗΣΗΣ',shop_address:street + " " +number,zip:zip,area:area}
        full_addr["Α/Α"] = 0
        setStartingAddr(full_addr)
      //  console.log(full_addr)
        setWithStarting(true)
        setSet(true)
        

    }
   

    return (
        !set ?
        <Grid className={classes.container}>
            <Typography  variant ='h6'>Σημείο Εκκίνησης</Typography>
            <Typography align='center' variant ='caption'>*Μπορείτε να συνεχίσετε χωρίς να ορίσετε σημείο εκκίνησης.</Typography>
            <div className={classes.whitespace}/>
            <TextField className ={classes.street}   value={street} onChange = {onStreetChange} label = 'Οδός'/>
            <TextField className ={classes.number}  value={number} onChange = {onNumberChange} label = 'Αριθμός' />
            <TextField className ={classes.zip} value={zip} onChange={onZipChange} label = 'ΤΚ'/>
            <TextField className ={classes.area}    value={area} onChange={onAreaChange} label = 'Περιοχή'/>
            <Button className = {classes.button2}   onClick = {handleSubmit} variant="contained" color='primary'>Επιβεβαίωση</Button>
        </Grid>
        : <Grid className={classes.container}>
            <Typography  align='center'> Προστέθηκε σημείο εκκίνησης.<DoneIcon className = {classes.icon}/></Typography>
            
    </Grid>

    );

}

export default StartingPoint;