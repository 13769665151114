/* global google */
import React, {useEffect,useState} from 'react'
import { GoogleMap, useJsApiLoader} from '@react-google-maps/api';
import CustomMarker from './CustomMarker';
import MapDirectionsRenderer from './DirectionsRenderer';
import { render } from '@testing-library/react';




const containerStyle = {    // Map Size
    width: '125vh',
    height: '90vh'
  };
  
const MapComponent = ({markers,setMarkersList,markersList,showRoute,setDistance,setDuration,withStarting}) => {     //markers = All Markers ,markersList = The clicked Markers
  
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey:"AIzaSyDcxXTLU55s9HxZaHcPWofqBUujAhYuSrk"
  })

  
  const mapRef = React.useRef(null);

  const [position,setPosition] = useState(markers[0].position)                  //Map initial Center    
    
  useEffect(() => {
    // console.log("ya",markersList)
  },[markersList])


  function handleLoad(map) {
    mapRef.current = map;
  }
        
  function handleCenter() {
    if (!mapRef.current) return;    
    setPosition(mapRef.current.getCenter().toJSON());      
  }

  return (isLoaded && markers[0]) ? (
    <GoogleMap 
      key = {1}
      zoom={10}
      onLoad={handleLoad}
      onDragEnd={handleCenter}
      center={position}
      id="map"
      mapContainerStyle = {containerStyle}
    >


      {/* Placement of Markers for Locations that are not chosen so marker is red */}
      {markers.map(marker => (                            
        !showRoute &&!marker.station &&           //showRoute = false means that route is not yet calculated , marker.station = false means that location is not chosen yet
          <CustomMarker
            key = {marker.id}
            shop_name = {marker.name}
            text = {marker.name + " " + marker.shop_address}
            position = {marker.position}
            id = {marker.id}
            station = {marker.station}
            
            onClick = {e =>
              { if (!marker.station){
                  let newMarker   = marker
                  newMarker["station"] = true;
                  setMarkersList(oldArray => [...oldArray, newMarker])
                  marker.station = true;
                  render() 
                }
              }}


            onRightClick = {e =>{setMarkersList(markersList.filter(item => item.id !== marker.id))}}
          />
          
                      
          // onClick = {e => {                 center to market that was clicked
          //     setPosition(marker);
          // }}
          
      ))}
      
      {/* Placement of Markers for Locations that are chosen so marker must be green */}
      {markersList.map((marker,index) => (
        <CustomMarker
          name ={marker.id}
          key = {marker.id}
          text = {marker.name + ", " + marker.shop_address}
          position = {marker.position}
          id = {marker.id}
          station = {marker.station}
          showRoute = {showRoute}
          routeIndex = {withStarting ? index : index+1}
          onClick = {e =>
            { if (!marker.station){
                let newMarker   = marker
                newMarker["station"] = true;
                setMarkersList(oldArray => [...oldArray, newMarker])
                marker.station = true;
                render() 
              }
            }
          }

          onRightClick = {e =>{ 
            markers.forEach(function (mark) {
              if (mark.id === marker.id && mark.id !== 0) {
                mark.station = false;
              }
            })
            if (marker.id !== 0)
              setMarkersList(markersList.filter(item => item.id !== marker.id))    
          }}
        />
          
      ))}


      {/* Show Route*/}
      {showRoute && 
        <MapDirectionsRenderer
          places={markersList}
          travelMode={google.maps.TravelMode.DRIVING}
          onLoad = {handleLoad}
          setDistance = {setDistance}
          setDuration = {setDuration}
        />
      }
      
      <></>
    </GoogleMap>

  ) : <></>
}

export default React.memo(MapComponent);
