const AppStyle = {
    root: {
        flexGrow: 1,
        //backgroundColor: '#ff0000'
        backgroundColor: '#f8f8ff'
      },
      paper: {
        marginTop: '44px',
        marginLeft: '2px',
        backgroundColor: '#f8f8ff'
        //backgroundColor: '#ff0000'
      },
      scrollable: {
      overflow: 'auto',
      height: '98vh',
      'overflow-x':'hidden',
      backgroundColor: '#f8f8ff'
     // backgroundColor: '#ff0000'
    },
     layout: {
       backgroundColor: '#f8f8ff'
     },
     whitespace: {
      flexGrow: 1
    },
    appbar: {
      background: 'transparent',
      boxShadow: 'none',
      backgroundColor: '#f8f8ff'
    },
    toolbar:{
      minHeight: '16px'
    },
    button: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      color: 'red'
    
    }
    


    
}

export default AppStyle;