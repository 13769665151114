/* global google */
import React, { useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { Typography } from '@material-ui/core';


const CustomMarker = ({text,position ,onClick,id,station,showRoute,routeIndex,onRightClick}) => {

    const green = {
        path: `M13.04,41.77c-0.11-1.29-0.35-3.2-0.99-5.42c-0.91-3.17-4.74-9.54-5.49-10.79c-3.64-6.1-5.46-9.21-5.45-12.07
            c0.03-4.57,2.77-7.72,3.21-8.22c0.52-0.58,4.12-4.47,9.8-4.17c4.73,0.24,7.67,3.23,8.45,4.07c0.47,0.51,3.22,3.61,3.31,8.11
            c0.06,3.01-1.89,6.26-5.78,12.77c-0.18,0.3-4.15,6.95-5.1,10.26c-0.64,2.24-0.89,4.17-1,5.48C13.68,41.78,13.36,41.78,13.04,41.77z
            `,
        fillColor: '#1EA362',
        fillOpacity: 1,
        strokeColor: '#ffffff',
        strokeWeight: 1,
        anchor: new google.maps.Point(14, 43),
        labelOrigin: new google.maps.Point(13.5, 15)
      };
    
    const blue = {
        path: `M13.04,41.77c-0.11-1.29-0.35-3.2-0.99-5.42c-0.91-3.17-4.74-9.54-5.49-10.79c-3.64-6.1-5.46-9.21-5.45-12.07
            c0.03-4.57,2.77-7.72,3.21-8.22c0.52-0.58,4.12-4.47,9.8-4.17c4.73,0.24,7.67,3.23,8.45,4.07c0.47,0.51,3.22,3.61,3.31,8.11
            c0.06,3.01-1.89,6.26-5.78,12.77c-0.18,0.3-4.15,6.95-5.1,10.26c-0.64,2.24-0.89,4.17-1,5.48C13.68,41.78,13.36,41.78,13.04,41.77z
            `,
        fillColor: '#2E8BC0',
        fillOpacity: 1,
        strokeColor: '#ffffff',
        strokeWeight: 1,
        anchor: new google.maps.Point(14, 43),
        labelOrigin: new google.maps.Point(13.5, 15)
      };
    
    const red = {
        path: `M13.04,41.77c-0.11-1.29-0.35-3.2-0.99-5.42c-0.91-3.17-4.74-9.54-5.49-10.79c-3.64-6.1-5.46-9.21-5.45-12.07
            c0.03-4.57,2.77-7.72,3.21-8.22c0.52-0.58,4.12-4.47,9.8-4.17c4.73,0.24,7.67,3.23,8.45,4.07c0.47,0.51,3.22,3.61,3.31,8.11
            c0.06,3.01-1.89,6.26-5.78,12.77c-0.18,0.3-4.15,6.95-5.1,10.26c-0.64,2.24-0.89,4.17-1,5.48C13.68,41.78,13.36,41.78,13.04,41.77z
            `,
        fillColor: '#DD4B3E',
        fillOpacity: 1,
        strokeColor: '#ffffff',
        strokeWeight: 1,
        anchor: new google.maps.Point(14, 43),
        labelOrigin: new google.maps.Point(13.5, 15)
      };
    
    
    const [showInfoWindow,setShowInfo] = useState(false)


    function    handleMouseOver() {
        setShowInfo(true)
    }

    function    handleMouseExit() {
        setShowInfo(false)
    }

    return (
        !showRoute ?
            <Marker 
                position={position}  //lat,lng
                onMouseOver={handleMouseOver} //onMouseOver show information on a tooltip
                onMouseOut={handleMouseExit}
                onRightClick = {onRightClick} //onRightClick remove marker from list
                icon = {station ? !id ? blue: green : red} //green marker if selected , red if it's not 
                label = {station ? routeIndex.toString() : null } // if selected show the row number on the label
                onClick = {onClick}
                key={id}
                >
                    {showInfoWindow && (
                        <InfoWindow>
                            <Typography>{text}</Typography>
                        </InfoWindow>
                    )}
            </Marker>
            :
            <Marker 
                position={position}      
                onMouseOver={handleMouseOver}      
                onMouseOut={handleMouseExit}
                icon = {id ? green : blue}                     
                label = {showRoute ? routeIndex.toString() : null}  
                onClick = {onClick}
                key={id}
                >
            
                {showInfoWindow && (
                    <InfoWindow>
                    <Typography>{text}</Typography>
                    </InfoWindow>
                )}
                
            </Marker>

    );
    }
export default CustomMarker;