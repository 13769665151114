const SortMarkersStyle = {
    root: {
        //maxWidth: 360,
        backgroundColor: '#f8f8ff',
        marginTop: '32px',
        marginLeft: '4px'
  
      },
      listitems: {
        border : '1px black solid',
        borderRadius: 5,  
        height: 48,
        marginTop: '8px',
        'box-shadow': '5px 5px 8px grey'
  
      },
      button : {
        color :'#DD4B3E',
      }
    
}

export default SortMarkersStyle;