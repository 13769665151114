/* global google */
import React,{useState,useEffect} from "react";
import {DirectionsRenderer} from "@react-google-maps/api";

const MapDirectionsRenderer = (props) => {
    const {places,travelMode,setDistance,setDuration} = props;
    const [directions, setDirections] = useState(null);
    const [error, setError] = useState(null);

  
    useEffect(() => {
        const waypoints = places.map(p => ({
        location: { lat: p.position.lat, lng: p.position.lng },
        stopover: true
        }));
        const origin = waypoints.shift().location;
        const destination = waypoints.pop().location;
  
        const directionsService = new google.maps.DirectionsService({supressMarkers: true});


        //------------------gooogle Directions API call----------//
        directionsService.route(
            {
            origin: origin,
            destination: destination,
            travelMode: travelMode,
            waypoints: waypoints,
            //supressMarkers: true
            },
        (result, status) => {
          //console.log(result)
          if (status === google.maps.DirectionsStatus.OK) {
            //console.log("heyheyhey",status)
            //console.log(result)
            setDirections(result);
            var dist = 0;
            var time = 0;
            for (var i = 0; i < result.routes[0].legs.length; i++){                 //calculate estimate time and distance
                dist += result.routes[0].legs[i].distance.value
                time += result.routes[0].legs[i].duration.value
            }
            setDistance(dist);
            setDuration(time);
      
          } else {
            //console.log("heyheyhey",result)
            setError(status);
          }
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
  
    
    if (error) {
      console.log("error")
      return <h1>{error}</h1>;
    }
    else {
        //console.log("hello",directions)

        return directions && <DirectionsRenderer directions={directions} options={{ suppressMarkers: true }} />
    }
}


  export default React.memo(MapDirectionsRenderer)