import {Grid,AppBar,Typography,makeStyles,Button,Toolbar} from '@material-ui/core'
import './App.css';
import MapComponent from './components/Map/Map'
import {SortMarkers} from './components/Map/SortMarkers'; 
// eslint-disable-next-line
import {useEffect, useState} from 'react'
// eslint-disable-next-line
import styles from './AppStyle.js';
import ReactExport from "react-data-export";
import ExcelReader from './components/ExcelImport/ExcelReader';
import StartingPoint from './components/Starting Point/StartingPoint'



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;




const useStyles = makeStyles(styles);


const  App = () => {

  const [withStarting,setWithStarting] = useState(false)
  const [startingAddr,setStartingAddr] = useState('')
  const [url,setUrl] = useState([])
  const classes = useStyles();
  const [locations,setLocations] = useState([])
  const [markers,setMarkers] = useState([])              // data for the wanted location
  const [markersList,setMarkersList] = useState([])       // the locations that are shown in the UI List
  // console.log("app",markersList)
  const [routeBool,setRouteBool] = useState(false)       // Button to make the api call for directions is pressed
  const [duration,setDuration] = useState('')            // estimated time and distance
  const [distance,setDistance] = useState('')
  const [notChecked,setNotChecked] = useState('')

  useEffect(() => {
    

  if (locations) {

    setMarkers(locations)
  
  }
//  console.log(locations,"edwman")

  },[locations])




  const handleSubmit = async () => {                            //Find Route Button
    
    markers.map((marker) => (                                                                           //Save not chosen Locations on notChecked array
      markersList.includes(marker) ? null : setNotChecked(oldarray => [...oldarray,marker])
      ))
    
    let gurl = []
    markersList.forEach((marker,index) => {
      let b = markersList.length-1
      let i = index/10 >> 0
      let limit = b/10 >> 0
      
      if (i === 0 && limit > 0){
        if (index  === 0) gurl[i] = 'https://www.google.com/maps/dir/?api=1&origin=' + marker.position.lat + ',' + marker.position.lng +'&waypoints=';
        else if (index === 9)  gurl[i] +=  marker.position.lat + ',' + marker.position.lng 
        else if (index < 10)   gurl[i] += marker.position.lat + ',' + marker.position.lng  +'|'
      }
      else if (i > 0 && i <limit) {
        if (index % 10 === 9) gurl[i] +=  marker.position.lat + ',' + marker.position.lng 
        else if(index % 10 > 0 ) gurl[i] +=  marker.position.lat + ',' + marker.position.lng  + '|'
        else if(index % 10 === 0) {
          gurl[i-1]+= '&destination='+  marker.position.lat + ',' + marker.position.lng  +'&travelmode=driving'
          gurl[i] = 'https://www.google.com/maps/dir/?api=1&origin=' +  marker.position.lat + ',' + marker.position.lng  + '&waypoints='
        }
      }
      else if (i===limit) {
          if (b % 10 === 0){
            gurl[i-1]+= '&destination='+  marker.position.lat + ',' + marker.position.lng  +'&travelmode=driving'
          }
          else {
            if (index === b) gurl[i]+= '&destination='+  marker.position.lat + ',' + marker.position.lng  +'&travelmode=driving'
            else if (index % 10 === 0) {
              gurl[i-1]+= '&destination='+  marker.position.lat + ',' + marker.position.lng  +'&travelmode=driving'
              gurl[i] = 'https://www.google.com/maps/dir/?api=1&origin=' +  marker.position.lat + ',' + marker.position.lng  + '&waypoints='
              }
            else if (index < b-1) gurl[i] +=  marker.position.lat + ',' + marker.position.lng  + '|'
            else if (index === b-1) gurl[i] +=  marker.position.lat + ',' + marker.position.lng 
          }

      }      
    })
    setUrl(gurl)
    
    setMarkersList(markersList.map((mark,index) => {                                                  //ID is the RowNr 
      return{...mark,id:index+1}
      }))

    setRouteBool(true)                                          //flag that shows order of list is chosen and route is calculated

    //console.log(url)


    
    
  }



  //------------ function to initialize the map with the locations again.Τρέχει οταν πατηθεί το 'Δοκιμάστε Ξανά' κουμπί-------------------//
  const init = async () => {

    //console.log("hihi",markers)
    setMarkersList([])
    setMarkers(markers.map(x => {                             
      return {...x,station:false}                
     }))

    markers.forEach(mark => {
      if (mark.id === 0){
        mark.station = true
        let newMarker = mark
        newMarker.station = true;
        setMarkersList(oldarray => [...oldarray,newMarker])
      }
    })

    setRouteBool(false);
    setDuration('')
    setDistance('')
    setNotChecked('')
    //console.log("this",markers)
  }


   

  //-------------Φόρτωση άλλου αρχείου, επαναφέρει τον χρήστη στην αρχική σελίδα----------------//
  function refreshPage() {
    window.location.reload();
  }



  if (markers.length) return (
    <Grid className={classes.layout}>
      <AppBar >
        <Toolbar className={classes.toolbar}>
          <Typography variant = 'h4'
            align = 'center'
            color='secondary'>
              Shipping Route Planner
          </Typography>
          <Typography variant="caption" color='secondary' gutterBottom>©Qubiteq</Typography>
          <div className={classes.whitespace}/>
          <Button variant="contained" color='secondary' onClick={refreshPage}>Φορτωση αλλου αρχειου</Button>  
        </Toolbar>       
      </AppBar>


      <Grid
        container
        direction = "row"
        justify = "flex-start"
        alignItems = "flex-start"
        alignContent = "flex-start"
        className = {classes.root}
      >
        <Grid item xs className = {classes.paper}>
          <MapComponent
            withStarting = {withStarting}
            markers = {markers}
            setMarkersList = {setMarkersList}
            setMarkersHelp = {setMarkers}
            markersList = {markersList}
            showRoute = {routeBool}
            setDistance = {setDistance}
            setDuration = {setDuration}
          />
        </Grid>

        <Grid item xs display ="flex" className = {classes.scrollable}>
          <SortMarkers
            markersList = {markersList}
            setMarkersList = {setMarkersList}
            markers = {markers}
            LockList = {routeBool}
            setMarkers = {setMarkers}
            withStarting = {withStarting}

          />

          { (markersList.length > 2) && !routeBool ?

              <Button variant="contained" color="primary" fullWidth onClick={handleSubmit} href="#down"> 
                Υπολογισμος Διαδρομης!
              </Button> 
              :
              null
          }


          { routeBool ? 
              <Button variant="contained" color="primary" fullWidth onClick={init} >
                Δοκιμαστε ξανα!
              </Button>
              :
              null
          }


          { routeBool ?
              <div id="down">
                <Typography variant = "h6" align = 'center'>
                  Εκτιμώμενος Χρόνος = {(duration/60).toFixed(1) + " λεπτά"}  
                </Typography> 

                <Typography variant = "h6" id = 'estimate' align = 'center'>
                  Απόσταση = {(distance/1000).toFixed(1) + " χιλιομετρα"}
                </Typography>
                {url.map((url,index) => (
                  <a href={url}>Διαδρομή Μέρος {index+1}<br></br></a>
                  
                ))
                }
                
                <Typography align='center'>
                  {notChecked?.length > 0 ?
                    <ExcelFile filename={ "Route " + new Date().toLocaleString() + ""} element={<Button variant="contained" color='primary'>Download Excel File</Button>}>
                      <ExcelSheet data={markersList} name="In Route" >
                        {/* <ExcelColumn label="Position" value="position"/> */}
                        <ExcelColumn label="Α/Α" value="id"/>
                        <ExcelColumn label="Shop_id" value="Shop_id"/>
                        <ExcelColumn bold label="name" value="name"/>
                        <ExcelColumn bold label="afm" value="afm"/>
                        <ExcelColumn bold label="shop_address" value="shop_address"/>
                        <ExcelColumn bold label="zip" value="zip"/>
                        <ExcelColumn bold label="city" value="city"/>
                        <ExcelColumn bold label="area" value="area"/>
                        <ExcelColumn bold label="ΤΗΛ1" value="ΤΗΛ1"/>
                        <ExcelColumn bold label="ΤΗΛ2" value="ΤΗΛ2"/>
                        <ExcelColumn bold label="email" value="email"/>
                        <ExcelColumn bold label="ΠΕΜ" value="ΠΕΜ"/>
                        <ExcelColumn bold label="ΧΚΒ" value="ΧΚΒ"/>
                        <ExcelColumn bold label="ΣΑΚ" value="ΣΑΚ"/>
                        <ExcelColumn bold label="ΚΩΔΙΚΟΣ" value="ΚΩΔΙΚΟΣ"/>
                      </ExcelSheet>
                      <ExcelSheet data={notChecked} name="Not in Route">
                        <ExcelColumn label="Shop_id" value="Shop_id"/>
                        <ExcelColumn bold label="name" value="name"/>
                        <ExcelColumn bold label="afm" value="afm"/>
                        <ExcelColumn bold label="shop_address" value="shop_address"/>
                        <ExcelColumn bold label="zip" value="zip"/>
                        <ExcelColumn bold label="city" value="city"/>
                        <ExcelColumn bold label="area" value="area"/>
                        <ExcelColumn bold label="ΤΗΛ1" value="ΤΗΛ1"/>
                        <ExcelColumn bold label="ΤΗΛ2" value="ΤΗΛ2"/>
                        <ExcelColumn bold label="email" value="email"/>
                        <ExcelColumn bold label="ΠΕΜ" value="ΠΕΜ"/>
                        <ExcelColumn bold label="ΧΚΒ" value="ΧΚΒ"/>
                        <ExcelColumn bold label="ΣΑΚ" value="ΣΑΚ"/>
                        <ExcelColumn bold label="ΚΩΔΙΚΟΣ" value="ΚΩΔΙΚΟΣ"/>
                      </ExcelSheet>
                    </ExcelFile>
                    :
                    <ExcelFile filename="Route" element={<Button variant="contained" color='primary'>Download Excel File</Button>}>
                      <ExcelSheet data={markersList} name="In Route" >
                        {/* <ExcelColumn label="Position" value="position"/> */}
                        <ExcelColumn label="Shop_id" value="Shop_id"/>
                        <ExcelColumn bold label="name" value="name"/>
                        <ExcelColumn bold label="afm" value="afm"/>
                        <ExcelColumn bold label="shop_address" value="shop_address"/>
                        <ExcelColumn bold label="zip" value="zip"/>
                        <ExcelColumn bold label="city" value="city"/>
                        <ExcelColumn bold label="area" value="area"/>
                        <ExcelColumn bold label="ΤΗΛ1" value="ΤΗΛ1"/>
                        <ExcelColumn bold label="ΤΗΛ2" value="ΤΗΛ2"/>
                        <ExcelColumn bold label="email" value="email"/>
                        <ExcelColumn bold label="ΠΕΜ" value="ΠΕΜ"/>
                        <ExcelColumn bold label="ΧΚΒ" value="ΧΚΒ"/>
                        <ExcelColumn bold label="ΣΑΚ" value="ΣΑΚ"/>
                        <ExcelColumn bold label="ΚΩΔΙΚΟΣ" value="ΚΩΔΙΚΟΣ"/>
                      </ExcelSheet> 
                    </ExcelFile>
                    }
                </Typography>
              </div>
              :
              null
            }
        
        </Grid>   
      </Grid>
    </Grid>
  );
  else return(
    <div>
      <AppBar position ='static' >
        <Toolbar className={classes.toolbar}>
          <Typography variant = 'h4'
            align = 'center'
            color='secondary'>
              Shipping Route Planner
          </Typography>
          <Typography variant="caption" color='secondary' gutterBottom>©Qubiteq</Typography>
          <div className={classes.whitespace}/>
          <ExcelReader data = {locations} setData = {setLocations} startingAddr={startingAddr} setMarkersList={setMarkersList} withStarting={withStarting}> </ExcelReader>
        </Toolbar>
      </AppBar>
      <StartingPoint setStartingAddr={setStartingAddr} setWithStarting={setWithStarting}/>
    </div>
);
}

export default App;
